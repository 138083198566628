import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import style from "./clients.module.css"

// import classNames from "classnames/bind"
// const cx = classNames.bind(style)

export default function Clients() {
    const data = useStaticQuery(
        graphql`
          query {
            #
            clientAigImage: file(relativePath: { eq: "clients/client-aig.png" }) {
              ...defaultImageTreatment
            }
            #
            clientDalmiaImage: file(relativePath: { eq: "clients/client-dalmia.png" }) {
                ...defaultImageTreatment
            }
            #
            clientDurianImage: file(relativePath: { eq: "clients/client-durian.png" }) {
                ...defaultImageTreatment
            }
            #
            clientEastboundImage: file(relativePath: { eq: "clients/client-eastbound.png" }) {
            ...defaultImageTreatment
            }
            #
            clientEscortsImage: file(relativePath: { eq: "clients/client-escorts.png" }) {
            ...defaultImageTreatment
            }
            #
            clientHexagonImage: file(relativePath: { eq: "clients/client-hexagon.png" }) {
            ...defaultImageTreatment
            }
            #
            clientInterfaceImage: file(relativePath: { eq: "clients/client-interface.png" }) {
            ...defaultImageTreatment
            }
            #
            clientMazarsImage: file(relativePath: { eq: "clients/client-mazars.png" }) {
            ...defaultImageTreatment
            }
            #
            clientMetroImage: file(relativePath: { eq: "clients/client-metro.png" }) {
            ...defaultImageTreatment
            }
            #
            clientOxfordImage: file(relativePath: { eq: "clients/client-oxford.png" }) {
            ...defaultImageTreatment
            }
            #
            clientPdsImage: file(relativePath: { eq: "clients/client-pds.png" }) {
            ...defaultImageTreatment
            }
            #
            clientRbsImage: file(relativePath: { eq: "clients/client-rbs.png" }) {
            ...defaultImageTreatment
            }
            #
            clientSamsungImage: file(relativePath: { eq: "clients/client-samsung.png" }) {
            ...defaultImageTreatment
            }
            #
            clientSpencerstuartImage: file(relativePath: { eq: "clients/client-spencerstuart.png" }) {
            ...defaultImageTreatment
            }
            #
            clientTuvImage: file(relativePath: { eq: "clients/client-tuv.png" }) {
            ...defaultImageTreatment
            }
            #
            clientCountingImage: file(relativePath: { eq: "clients/client-counting.png" }) {
            ...defaultImageTreatment
            }
            #
          }
        `
    )
    return (
        <>
            <div className={style.wrapperClients} >
                <div className={style.allClients} >
                    <div className={style.header}>Clients</div>
                    <Img className={style.clientLogo}
                        fluid={data.clientAigImage.childImageSharp.fluid}
                        alt="clientAigImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientDalmiaImage.childImageSharp.fluid}
                        alt="clientDalmiaImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientDurianImage.childImageSharp.fluid}
                        alt="clientDurianImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientEastboundImage.childImageSharp.fluid}
                        alt="clientEastboundImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientEscortsImage.childImageSharp.fluid}
                        alt="clientEscortsImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientHexagonImage.childImageSharp.fluid}
                        alt="clientHexagonImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientInterfaceImage.childImageSharp.fluid}
                        alt="clientInterfaceImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientMazarsImage.childImageSharp.fluid}
                        alt="clientMazarsImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientMetroImage.childImageSharp.fluid}
                        alt="clientMetroImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientOxfordImage.childImageSharp.fluid}
                        alt="clientOxfordImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientPdsImage.childImageSharp.fluid}
                        alt="clientPdsImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientRbsImage.childImageSharp.fluid}
                        alt="clientRbsImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientSamsungImage.childImageSharp.fluid}
                        alt="clientSamsungImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientSpencerstuartImage.childImageSharp.fluid}
                        alt="clientSpencerstuartImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientTuvImage.childImageSharp.fluid}
                        alt="clientTuvImage" />
                    <Img className={style.clientLogo}
                        fluid={data.clientCountingImage.childImageSharp.fluid}
                        alt="clientCountingImage" />
                </div>
            </div>
        </>
    )
}


